import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2023 Hellawine Release Party',
	date: '2023-09-19',
	image: require('./2023-hellawine@2x.jpg'),
	body: (
		<>
			<p>
				Wake up from your summer snooze, folks. It’s time for our 5th annual festival of debauchery and
				devilishness, HELLAWINE. But this time, we’re doing it all in the dark.
			</p>
			<p>
				HELLAWINE 2023 is on October 28 at a NEW time! From 5-9pm. Come be a part of our Waynesboro takeover at
				the Old Mill for an evening festival of frights, bombastic music, and juicy wine delights from our
				newest 2021 vintage releases. You can catch wine tastings with our team + pours by the glass, tasty
				morsels from local faves Fat Tyler’s Meats n’ Such and Flora, plus a DJ and other hidden monsters.
			</p>
			<p>Costumes are encouraged but not required. Tickets are on sale NOW! You’ve got options:</p>
			<p>
				<strong>Early Freak Ticket is $40 (bumps up to $50 after 10/15)</strong>
				<br />
				<ul>
					<li>Tasting of All 2021 wines</li>
					<li>Delicious eats</li>
					<li>Live entertainment and spooky vibes</li>
				</ul>
			</p>
			<p>
				<strong>HELLA’Bundle is $110</strong>
				<br />
				<ul>
					<li>3 2021 bottles AND a little somethin' special for you.</li>
					<li>Tasting of All 2021 wines</li>
					<li>Delicious eats</li>
					<li>Live entertainment and spooky vibes</li>
				</ul>
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				October 28rd from 5-9PM
				<br />
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Lightwell Survey Winery & Tasting Room
				<br />
				200 W 12TH ST, UNIT# D53
				<br />
				Waynesboro, VA 22980
				<br />
				<a href="https://g.page/lightwellsurvey?share" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
