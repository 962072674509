import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2023 Redirecting The Light',
	date: '2024-05-02',
	image: require('./2023-redirecting-the-light@2x.png'),
	body: (
		<>
			<p>
				56% Vidal Blanc, 20% Chambourcin, 11% Petit Manseng, 9% Chardonel, and 4% Traminette. Textural and
				Elegant with delicate floral elements. Soft and subtle, hard do discern in a lacy, refreshing fruit
				manner. The palate comes off slightly savory backed by fresh white cherry and wild strawberry
				highlights. This is a more complex drink than a simple rose, but still totally crushable. Drink away the
				day. 143 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Rainbow Hill Vidal Blanc and Chardonel, Middlebrook Chambourcin and Traminette, Sherman Ridge Petit
				Manseng
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				This is a wine that changes from year to year. We change the name, the label design, and the approach to
				the wine is always evolving. For the past few years, we have been using both white and red grapes to
				make something that is usually rosé oriented, skin contact adjacent, or vice versa. This year we lean a
				little harder towards rosé, but stay away from the pale and crisp, simply fruited, provençal style that
				has come to define industrial versions of this category. There are wonderful, hand crafted bottlings of
				that approach, but we love a rosé with layered flavors and a deeper texture, so we work with a variety
				of grapes to build nuance and density, and we allow the wine to go through malo, so we don’t have to
				filter. With a small addition of SO2, we find a wine that is charming, with creamy fruit at bottling and
				that develops over time, daring us to take it more seriously. You don’t have to do that, but you can!
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.37
				<br />
				TA: 6.1 g/L
				<br />
				Free SO2 at bottling: Probably ~ 9ppm 14ppm total addition
				<br />
				Alcohol: 11.6% abv
				<br />
				R/S: 0.5 g/L
				<br />
				Malic Acid: 0.1g/L
				<br />
				Acetic acid at bottling: 0.4 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
