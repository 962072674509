import { React } from '@/vendor'
import styles from './styles.module.scss'

function VinoFrame() {
	const [hasInit, setHasInit] = React.useState(false)
	const [retry, setRetry] = React.useState(0)
	React.useEffect(() => {
		if (!hasInit) {
			if (window.Vinoshipper) {
				window.Vinoshipper.init(1566, {})
				setHasInit(true)
			} else {
				setRetry((prev) => prev + 1)
			}
		}
		return () => {
			if (window.Vinoshipper) {
				window.Vinoshipper.loaded = false
			}
		}
	}, [hasInit, retry])
	return <div className={`vs-products ${styles['vs-products']}`}></div>
}

export default VinoFrame
