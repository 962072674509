import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2015 Goodbye Horses',
	date: '2017-11-01',
	image: require('./2015-goodbye-horses@2x.png'),
	body: (
		<>
			<p>
				100% Riesling. Wild horses are unbridled and fiercely independent, but still prefer to exist within a
				group. They’re a powerful embodiment of the natural conflict between beauty and violence, even in the
				most serene environments.
			</p>
			<p>
				From Ox-Eye Vineyards, <i>Goodbye Horses</i> is a charismatic representation of a dry Riesling. In
				Virginia, grapes grown at higher elevations ripen later in the season and preserve their acidity.
				Shenandoah Valley Rieslings are distinct from the rest of the world in that they can be dry, with strong
				yet balanced acidity (at less than 12% abv). The nose pushes hard on fresh Granny Smith apple and white
				flower elements, while the palate dials up the acidity. This wine will leave you wanting more. 55 cases
				produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				100% Shenandoah Valley. Ox-Eye Riesling.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Simple. Foot tread then whole cluster pressed. Juice racked relatively dirty and fermented with ambient
				yeasts in stainless barrel. Wine left on gross lees in stainless and 228 Liter neutral barrel for 7
				months. No malolactic. Bottled May 2016.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.24
				<br />
				TA: 6.1 gm/L
				<br />
				Free SO2 at bottling: 16ppm
				<br />
				Alcohol: 11.50% abv
				<br />
				G/F: 2.45 g/L (0.25%)
				<br />
				Malic Acid: 1.91 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
