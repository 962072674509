import { React } from '@/vendor'

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width={24}
		height={24}
		style={{
			background: '#252527',
		}}
		{...props}
	>
		<defs>
			<path
				id="a"
				d="M12 0c6.625.007 11.996 5.375 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm1.35 6.006c-2.11 0-3 1.336-3 2.748v1.752h-1.5v1.998h1.5v5.502h2.25v-5.502h2.098l.152-1.998H12.6v-1.5c0-.586.111-1.002.873-1.002h1.295l.082-1.886-.074-.012-.126-.017a10.425 10.425 0 0 0-1.3-.083Z"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<path fill="#252527" d="M0 0h24v24H0z" />
			<use xlinkHref="#a" fill="#FFF" />
		</g>
	</svg>
)
