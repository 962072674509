import Fs from '../Fs'
import Grid from '../Grid'
import { Link, PropTypes, React } from '@/vendor'
import styles from './styles.module.scss'

class LinkCell extends React.PureComponent {
	static propTypes = {
		pre: PropTypes.node,
		primary: PropTypes.string,
		to: PropTypes.string,
	}
	render() {
		const { pre, primary, to, ...props } = this.props
		const isExternal = typeof to === 'string' && to.startsWith('http')
		return (
			<Grid.Content
				pad={1}
				className={styles.linkCell}
				tag={isExternal ? 'a' : Link}
				href={isExternal ? to : undefined}
				to={isExternal ? undefined : to}
				target={isExternal ? '_blank' : undefined}
				{...props}
			>
				<Fs align="center">
					{pre}
					<Fs tag="h2" variant="h2">
						{primary}
					</Fs>
				</Fs>
			</Grid.Content>
		)
	}
}

export default LinkCell
