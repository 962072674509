import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 Hellawine Release Party',
	date: '2021-10-01',
	image: require('./2021-hellawine@2x.jpg'),
	body: (
		<>
			<p>
				Friends, Countrymen, Wine drinkers! Its that time of year again when things get a little creepier, a
				little spookier, and dark WAY earlier. Its also time for HELLAWINE!!!!!!!!! Get your Tickets now! Come
				and join us on Saturday October 23rd at our tasting room in Waynesboro! Ghosts and ghouls will be
				frantically sipping away on our latest release, 2019! There will be food and wine for sale and weird
				stuff galore! This is an indoor/outdoor party so you may need a jacket, but who knows! We are a safe
				space so if you are wearing a halloween mask, please don't forget your medical one while inside the
				tasting room as well.
			</p>
			<p>
				Our 2019’s are available for presale for pickup on the 23rd. Select “winery pickup” at check out. If you
				can't make it and need us to ship the new juice to you we will start shipping the second week of
				November.
			</p>
			<p>
				Rumor is there may be some of our earlier vintages available, not completely sure what's in the cellar.
				However, Hellawine will be the only chance to break into the vault.
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				October 23rd from 1-5PM
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Lightwell Survey Winery & Tasting Room
				<br />
				200 W 12TH ST, UNIT# D53
				<br />
				Waynesboro, VA 22980
				<br />
				<a href="https://g.page/lightwellsurvey?share" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>WHAT TO BRING?</strong>
				<br />
				Good attitude, money, blanket and mask. If we get our act together, we should have official Lightwell
				Survey sweatshirts availble for purchase at the event.
			</p>
			<p>
				<strong>ATTIRE</strong>
				<br />
				There will be costumes so rock your spookiest, funniest, or scariest. As always, you don't have to dress
				up but we always appreciate everyone's creativity.
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
