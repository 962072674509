import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2019 Hintermen',
	date: '2021-09-06',
	image: require('./2019-hintermen@2x.png'),
	body: (
		<>
			<p>
				A blend of 68% Petit Manseng and 32% Riesling. Racy green apple mouth puckering acidity. Makes your eyes
				water a bit in the best way. Smells and looks shimmery, silver flecky almost reflective. The brightness
				of both varietals really shine this vintage, from the up front high acid apple bomb, to the pear/apricot
				roundness in the mid palate. The quality of the vintage and the elevation of the vineyards really smash
				through the glass this year. Maybe Sebastian's wine of the vintage. 125 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Mount Airy vineyard in Fort Defiance, grower Tim Jordan; Sherman Ridge vineyard in Woodstock, grower
				Sunset Hills
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				These two grapes were fermented together in large (500 to 600L), neutral puncheons. The alcoholic
				fermentation lasted a full twelve months, during which time the wine went through malolactic. It was
				bottled unfiltered. As this wine evolves and our access to Riesling diminishes, it is more and more
				about the expression of Shenandoah Valley Petit Manseng. The Riesling moderates the alcohol and sings
				the high notes aromatically, but the Manseng is behind the wheel.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.1
				<br />
				TA: 6.8 g/L
				<br />
				Free SO2 at bottling: 11 ppm
				<br />
				Total SO2: 59 ppm
				<br />
				Alcohol: 13.7 abv
				<br />
				G/F: 2.5 g/L
				<br />
				Malic Acid: 0.09 g/L
				<br />
				Acetic acid at bottling: 0.48 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
