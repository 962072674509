import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2019 Strange Days',
	date: '2020-02-14',
	image: require('./2019-strange-days@2x.png'),
	body: (
		<>
			<p>
				A blend of 65% Pinot Gris, 15% Blaufränkisch, 10% Petit Manseng, and 10% Riesling. An homage to summer,
				a longing for the past, or perhaps memories of better days calling across the water. Beware the danger
				in nostalgia, however, as a beckoning mermaid is sometimes a menacing siren hiding a deadly hand.
				Instead, we look forward and beat against the waves, remembering a smooth sea never made a skilled
				sailor.
			</p>
			<p>
				Close your eyes, you’re in an apple orchard with this woodsy wine that is confusing and delightful.
				There is freshness and life, yet also bramble that can only come from red wine. This is some strange
				shit, my friends. We’re not even sure what to think about this orange wine - meets light red - meets
				rosé, but it tastes so good in 2020. The fresh-crunchy-juicy is more Pinot Gris than Blau, but Blau is
				creeping around the corner with the soft, silky tannins. This is rosé through a different lens. 140
				cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Ridge Run Pinot Gris, Mount Airy Blaufränkisch, Sherman Ridge Petit Manseng, Mount Airy Riesling, all
				from the Shenandoah Valley.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				100% whole cluster, no SO2 on the fruit, on skins for twelve days, semi carbonic and then pressed.
				Primary and malo in neutral barrels, aged on lees until bottling. The longer aging led to more savory
				flavors and a lifted, snappy character from the volatile acidity. Bottled unfiltered on 6/12/20 with a
				total of 30ppm of added SO2.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.60
				<br />
				TA: 5.10 gm/L
				<br />
				Free SO2 at bottling: 4ppm
				<br />
				Alcohol: 11.10% abv
				<br />
				RS: 0.68 g/L (0.07%)
				<br />
				Malic Acid: {'<'}0.05 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
