import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 The Weird Ones Are Wolves',
	date: '2023-10-10',
	image: require('./2021-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 85% Cabernet Franc and 15% Petit Manseng. Dark burnt campfire and toasted herbs are the
				dominant profile here. Gnarled, stewed fruit, white pepper, leather, and smoke make this a beef-tastic
				yum-fest. Someone pass the Worcestershire. 216 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Cabernet Franc, Sherman Ridge Petit Manseng
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Both grapes were co-fermented with 15% Whole cluster, the Petit Manseng being direct press and half
				whole cluster. Aged for 11 months in neutral French 500L and 228L barrels. Bottled August 2020.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.90
				<br />
				TA: 4.5 g/L
				<br />
				Free SO2 at bottling: 19 ppm
				<br />
				Alcohol: 13.8 abv
				<br />
				G/F: 0.10 g/L (0.01%)
				<br />
				Malic Acid: 0.05 g/L
				<br />
				Acetic acid at bottling: 0.61 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
