import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2016 The Weird Ones Are Wolves',
	date: '2018-11-01',
	image: require('./2016-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 97% Cabernet Franc, 2% Petit Manseng, and 1% Riesling. It’s hard to stay a lone wolf for
				long, so you’ve got to stay looking over your shoulder as the weird ones are out there. It could be time
				to show a little teeth and let them know your bark isn’t worse than your bite. There are always new
				wolves in town equally hungry and equally weird. Don’t be afraid to let ‘em know who leads the pack.
			</p>
			<p>
				Beefy, juicy, spicy, and so so yummy! This one is soft and silky on the palette with a nice gravely
				acidity that cleans it right up. FRESHHHHHH to death. Currants, raspberry, and prunes are front runners
				while smokey wood and ash on the finish help balance it out. While the fruit is very pretty, its the
				spice that grabs ahold of you. There is a cedar clove element that shines through that is oh so elegant
				– oh so soft. Stay gold pony boy. 169 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Cabernet Franc and Bailey Cabernet Franc, both from the Shenandoah Valley.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Simple. 20% whole cluster. Fermented with ambient yeasts. Malolactic and aging in old 228L French
				barrels. No racking and aged with low level of SO2. Bottled August 2017.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.80
				<br />
				TA: 4.80 gm/L
				<br />
				Free SO2 at bottling: 18ppm
				<br />
				Alcohol: 13.10% abv
				<br />
				G/F: 0.13 g/L
				<br />
				Malic Acid: 0.06 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
