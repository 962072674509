import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2022 Colorwave',
	date: '2023-05-06',
	image: require('./2022-colorwave@2x.png'),
	body: (
		<>
			<p>
				A blend of 44% Vidal Blanc, 33% Petit Manseng, 19% Chambourcin, and 4% Traminette. The name suits this
				wine. Its hard to describe the color, its kind of a sliding scale out to the rim. The nose is wildly
				fragrant, full of fleshy red fruit and colorful flowers. The palate is entirely different. While the
				fruit is there, its a more underripe version of it. The subtle tannin structure implies an almost
				apple-like texture. Very generous and exuberant, it is a soft grippy drink that never gets boring. 395
				cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Sherman Ridge Vineyard in Woodstock for Vidal blanc and Petit Manseng, Bluestone Vineyard in Bridgewater
				for Vidal blanc and Chambourcin, Rainbow Hill Vineyard in Harrisonburg for Vidal blanc and Petit
				Manseng, Barren Ridge Vineyard in Fishersville for Traminette.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Combination of direct press and whole cluster fermentation (three weeks on skins) on Vidal, two day skin
				maceration on Traminette, no SO2 on the fruit, Chambourcin on the skins for eight days, then pressed and
				combined with everything else in a big co-fermented varietal soup. It finished primary and malo in tank
				and aged on lees until bottling.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.40
				<br />
				TA: 5.5 gm/L
				<br />
				Free SO2 at bottling: 15ppm
				<br />
				Alcohol: 11.50% abv
				<br />
				RS: 0.50 g/L
				<br />
				Malic Acid: {'<'}0.05 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
