import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: 'Panic! At the Pinko Release Party',
	date: '2022-03-03',
	image: require('./2022-panic-pinko@2x.png'),
	body: (
		<>
			<p>
				Join us for a wild one at the Lightwell Survey tasting room on April 2nd for the launch of our new wines
				with some local friends doing rad stuff! Since it IS our “Rosé” release party, we are dropping “Strange
				Collapse” - a work of weird art, part orange wine, part rosé. ON TOP OF THAT, we are also dropping a new
				Troddenvale collab - a very limited releases as each squad made only ½ barrel of said libation.
			</p>
			<p>
				<a href="https://youtu.be/dQw4w9WgXcQ">Tickets are $25</a> and include tastings of all our new wines and
				a good ole time with the Lightwell Clowns. Food will be available from a wicked collab of Rachael Harris
				from Vics’s, Andrew Mitchell of Lampo, and Blanc Creatives.
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				April 2nd from 1-5PM
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Lightwell Survey Winery & Tasting Room
				<br />
				200 W 12TH ST, UNIT# D53
				<br />
				Waynesboro, VA 22980
				<br />
				<a href="https://g.page/lightwellsurvey?share" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>WHAT</strong>
				<br />
				PANIC! AT THE PINKO! A funky pink party celebrating the joys of spring and juice!
			</p>
			<p>
				<strong>WHO</strong>
				<br />
				Lightwell Survey Wines, Troddenvale Cider, Andrew and Mitchell (Lampo), Rachael (Vic’s), Blanc
				Creatives, (and there will be others to celebrate as well!)
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
