import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 Pinko de Mayo Release Party',
	date: '2021-05-01',
	image: require('./2021-pinko@2x.png'),
	body: (
		<>
			<p>We’re having a party on May 1st, which some may point out, is near Cinco de Mayo!</p>
			<p>
				We will be convening at our space in Waynesboro for the 4th Annual Pinko de Mayo Release Party. Join us
				for a great day celebrating the release of our 2020 spring lineup. We have a new 'Strange Hybrid
				Moments' rosé plus two fruit wine collaborations, 'Night Moves' + 'We Are Not Noble'. This fruit wine
				was a collab with our friends at Troddenvale. Your ticket includes tastings of our latest and greatest
				chillable wines. We will have wine for sale and loads of pink for you. So please So come on out to the
				'Boro and have some fun with us. The party will be outdoors in the sun with food available for purchase
				from local badass biscuit whiz from Staunton VA, Rachael Harris and Vagabond Belly. Please{' '}
				<a href="https://youtu.be/dQw4w9WgXcQ">purchase your ticket here</a>, we will have your name on our
				guestlist when you arrive!
			</p>
			<p>
				The only way to get our new 2020 wines now is to pre-order for pickup on the 1st. Select “winery pickup”
				at check out. You can purchase the new 2020 rosé and our 2020 fruit wine collabs{' '}
				<a href="/shop">here</a>! If you can't make it and need us to ship the new juice to you we will start
				shipping after the event.
			</p>
			<p>
				We are offering all 2 of the wines in either a 6-pack or 3-pack. Don’t want to scare anyone but our 2020
				fruit wine is very limited so we will sell out quickly!
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				May 1st from 1-5PM
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Lightwell Survey Winery & Tasting Room
				<br />
				200 W 12TH ST, UNIT# D53
				<br />
				Waynesboro, VA 22980
				<br />
				<a href="https://g.page/lightwellsurvey?share" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>WHAT TO BRING?</strong>
				<br />
				Good attitude, dress to be outside, and bring yo' mask.
			</p>
			<p>
				<strong>ATTIRE</strong>
				<br />
				Let's be safe, and dress to impress with your best pink shirt, pink mask, pink pants, pink shoes, pink
				hair or don't.
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
