import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2023 Pinko de Mayo Release Party',
	date: '2023-04-15',
	image: require('./2023-pinko@2x.png'),
	body: (
		<>
			<p>
				Spring is springing, AI is taking over, and the world is still collapsing. But don't let our imminent
				demise get you down, because we have just the way to forget all that shit... It's Pinko De Mayo Time!
			</p>
			<p>
				Join us on May 6 at our tasting room in the field in Waynesboro, VA for our spring soirée. Be among the
				first to try THREE of our latest Bacchanalian beverages, including our not rosé "Colorwave," and our
				latest collaboration with Troddenvale. And rumor has it that "Goodbye Horses" will be making its first
				appearance at Pinko!
			</p>
			<p>Supreme sustenance will be available from Charlottesville’s Lampo and biscuit badass Rachael Harris.</p>
			<p>
				Join the mayhem from 1-5 pm. There will be other surprises, most of which will be new to us as well, so
				we'll experience them together. Tickets are $25 per person and must be purchased in advance.{' '}
				<a href="https://checkout.square.site/buy/6DCLYWEW4Y3GI3TAN5YSKRUO">Purchase your tickets here!</a>
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				May 6th from 1-5PM
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Lightwell Survey Winery & Tasting Room
				<br />
				200 W 12TH ST, UNIT# D53
				<br />
				Waynesboro, VA 22980
				<br />
				<a href="https://g.page/lightwellsurvey?share" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>WHAT</strong>
				<br />A funky pink party celebrating the joys of spring and juice!
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
