import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2024 Pinko de Mayo Release Party',
	date: '2024-04-15',
	image: require('./2024-pinko@2x.png'),
	body: (
		<>
			<p>
				Pinko is landing back at{' '}
				<a href="https://www.primrosedc.com" target="_blank">
					Primrose DC
				</a>
				, Sunday, May 5
			</p>
			<p>
				It’s that time again for our annual celebration of the Vernal Equinox, the color pink, and day drinking.
				But this time we’re back to where we first partied together, bringing the Pinko back to Primrose in DC
				on Sunday, May 5 from 1 to 4PM.
			</p>
			<p>
				Come and enjoy the first tastings of our new wine releases: 2023 Redirecting the Light (not a rosé),
				2023 The Way It Was Before (a fresh new red), 2022 Goodbye Horses, and TWO versions of the Family Ties
				Collab with Troddenvale. That’s FIVE wines coming out – our biggest spring lineup to date. And on top of
				that, we will be crushing a tasty assortment of eats provided by Seb and the gang at Primrose.
			</p>
			<p>
				Tickets are $40 per person and included allll that above. Not to make you freak out, but, to make you
				freak out, we suggest you make the move now before the price jumps to $50 on 4/21 and space is limited.
				So Invite your lovers, your friends, your enemies – we’re all drinking that good juice under the Big Top
				at Primrose on May 5.
			</p>
			<p>
				And Waynesboro fam, fear not. Though the party has moved north this year, we didn’t forget about you.
				Stay tuned for good times coming your way but rumor is we will be open for tastings every Saturday all
				Summer!{' '}
				<a href="https://squareup.com/appointments/book/rvoul39wv4dcmp/LQFV0SYD1EBKS/services">
					Book your tasting now!
				</a>
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				May 5th from 1-4PM
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Primrose
				<br />
				3000 12th Stree NE
				<br />
				WASHINGTON, D.C. 20017
				<br />
				<a href="https://maps.app.goo.gl/dfEbfxw9p12y7cmMA" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>WHAT</strong>
				<br />A funky pink party celebrating the joys of spring and juice!
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				Hit us up on{' '}
				<a href="https://www.instagram.com/lightwellsurvey/" target="_blank">
					IG
				</a>{' '}
				or <a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
