import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 Goodbye Horses',
	date: '2023-05-06',
	image: require('./2021-goodbye-horses@2x.png'),
	body: (
		<>
			<p>
				A blend of 75% Riesling and 25% Petit Manseng. Crisp & Clean. Almost glassy in its texture, it hides the
				acid well with the fleshy Petit Manseng in the blend. Lemon shortbread and grapefruit join forces with a
				dash of Sprite and tangerine pith.  The wine is linear and to the point, accurate and sharp but supple
				where it needs to be. Drink it now, or forever hold your peace. 74 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Shenandoah Vineyards in Edinburgh for Riesling; Sherman Ridge Vineyard in Woodstock for Petit Manseng.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Whole cluster pressed. Juice left with lees and fermented with ambient yeasts in stainless and neutral
				puncheon. Wine left on fermentation lees in barrel for 7 months without SO2. Full malolactic. Bottled
				unfiltered in May 2022.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.30
				<br />
				TA: 5.6 g/L
				<br />
				Free SO2 at bottling: 18 ppm
				<br />
				Alcohol: 11.1% abv
				<br />
				G/F: 1.0 g/L
				<br />
				Malic Acid: > 0.05g/L
				<br />
				Acetic acid at bottling: 0.35 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
