import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 Hintermen',
	date: '2023-10-10',
	image: require('./2021-hintermen@2x.png'),
	body: (
		<>
			<p>
				A blend of 70% Petit Manseng, 15% Riesling, and 15% Vidal Blanc. Rich layers of white lily and
				honeysuckle give way to tart orange marmalade. The golden color implies a richness that seems present,
				but also not. The crisp apple-y acidity cuts through any fat the wine might have had and instead leaves
				you wanting more. 158 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Sherman Ridge Petit Manseng and Vidal blanc, Shenandoah Vineyards Riesling
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				These three grapes were fermented together in large (500 to 600L), neutral puncheons. The alcoholic
				fermentation lasted 8 months, during which time the wine went through malolactic. It was bottled
				unfined/unfiltered. While the previous Hintermen relied solely on Vidal blanc to provide counterpoint to
				the Petit Manseng, in 2021 we had access to older vine Riesling from the northern Shenandoah Valley. The
				Riesling and Vidal moderate the alcohol, especially in the drier, warm 2021 vintage where the Petit
				Manseng wanted to keep on ripening. The texture, density and color show the mark of Manseng, while the
				Vidal and Riesling contribute aromatically and reign the wine in a bit. But there is no denying this is
				a thick year for Hintermen, after all it is 70% PM, and we love that.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.36
				<br />
				TA: 6.5 g/L
				<br />
				Free SO2 at bottling: 8 ppm
				<br />
				Alcohol: 13.6 abv
				<br />
				G/F: 1.7 g/L (0.17%)
				<br />
				Malic Acid: 0.05 g/L
				<br />
				Acetic acid at bottling: 0.59 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
