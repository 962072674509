import { React } from '@/vendor'

export default (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
		<g fill="none" fillRule="nonzero">
			<path fill="#252527" d="M0 0h24v24H0z" />
			<path
				fill="#FFF"
				d="M12 0c6.625.007 11.996 5.375 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm.673 6.007h-1.346c-.71.001-1.04.006-1.352.017l-.17.006-.279.013c-.638.029-1.075.13-1.456.278a2.94 2.94 0 0 0-1.063.692 2.94 2.94 0 0 0-.692 1.063c-.148.382-.25.818-.279 1.457l-.012.277-.007.171c-.01.313-.015.643-.016 1.353v1.345c.001.71.006 1.04.016 1.353l.007.17.012.278c.03.639.13 1.075.279 1.457.153.394.358.729.692 1.062.334.334.668.54 1.063.692.381.149.818.25 1.456.28l.278.012.17.006c.313.01.644.015 1.353.017h1.346c.71-.002 1.04-.006 1.352-.017l.17-.006.279-.013c.638-.029 1.075-.13 1.456-.279.395-.153.73-.358 1.063-.692.334-.333.539-.668.692-1.062.148-.382.25-.818.279-1.457l.012-.278.007-.17c.01-.313.015-.643.016-1.353v-1.345c-.001-.71-.006-1.04-.016-1.353l-.007-.17a61.712 61.712 0 0 0-.012-.278c-.03-.639-.13-1.075-.279-1.457a2.941 2.941 0 0 0-.692-1.063 2.94 2.94 0 0 0-1.063-.692c-.381-.148-.818-.25-1.456-.278a61.715 61.715 0 0 0-.278-.013l-.17-.006c-.313-.01-.644-.016-1.353-.017Zm.208 1.082a28.61 28.61 0 0 1 1.544.033c.585.027.902.125 1.114.207.28.109.48.239.69.449.21.21.34.41.448.69.083.211.18.529.207 1.114.024.533.032.751.034 1.764v1.321a31.382 31.382 0 0 1-.034 1.764c-.027.585-.124.903-.207 1.114-.108.28-.239.48-.448.69-.21.21-.41.34-.69.449-.212.082-.53.18-1.114.206-.533.025-.752.033-1.764.035h-1.542a28.605 28.605 0 0 1-1.544-.035c-.585-.026-.902-.124-1.114-.206a1.859 1.859 0 0 1-.69-.45c-.21-.209-.34-.409-.448-.689-.083-.211-.18-.53-.207-1.114a31.382 31.382 0 0 1-.034-1.764v-1.321c.002-1.013.01-1.231.034-1.764.027-.585.124-.903.207-1.114.108-.28.239-.48.448-.69.21-.21.41-.34.69-.449.212-.082.53-.18 1.114-.207.533-.024.752-.032 1.764-.034h1.542ZM12 8.925a3.081 3.081 0 1 0 0 6.162 3.081 3.081 0 0 0 0-6.162Zm0 1.081a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm3.203-1.922a.72.72 0 1 0 0 1.44.72.72 0 0 0 0-1.44Z"
			/>
		</g>
	</svg>
)
