import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: 'A Closer Look at Our Artist Series',
	date: '2021-11-11',
	image: require('./a-closer-look-at-our-artist-series@2x.jpg'),
	body: (
		<>
			<p>
				Our creative process has been pretty organic. It usually involves lots of good wine and weird ass ideas.
				From naming our wine, to the rad 'Hardy Boys' inspired label artwork – we try to keep the conversation
				open. There are no bad ideas, just ones that won't get through the TTB (the darn Alcohol and Tobacco Tax
				and Trade Bureau). Since our winemaking is minimal intervention in style, our wines naturally tend to
				vary each year. So from the start, we wanted to create personalities for each wine and capture their
				stories across each changing vintage. The illustrations are created as an artist series exploring
				literal and metaphorical images (there’s some weird shit going on) experimenting with color, lighting,
				and style. We're having a great journey doing our best to make fresh wines wrapped in badass artwork for
				a more textured and personal experience that folks look forward to enjoying.
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
