import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2023 The Way It Was Before',
	date: '2024-05-02',
	image: require('./2023-the-way-it-was-before@2x.png'),
	body: (
		<>
			<p>
				63% Chambourcin, 25% Petit Manseng, 7% Vidal Blanc, 2% Riesling, 2% Chardonel, and 1% Traminette.
				Explosive & vibrant. The Chamborcin is giving beaujo in the most purple way possible. Electric and
				infinitely drinkable, this is a summer drinkin’ red @ its best. The Petit Manseng really helps keep this
				juice bomb light on its toes while committing aromatics to the wine for days. Drink this wine with a
				hard chill, more than you think, almost white wine temp and rejoice. 162 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Bluestone Vineyard Chambourcin, Sherman Ridge Petit Manseng, Rainbow Hill Vidal Blanc and Chardonel,
				Shenandoah Vineyards Riesling, Middlebrook Traminette
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				This wine was inspired by our 2022 collaboration with Troddenvale where we floated Chambourcin grapes on
				pressed apple juice. For this wine, we substituted pressed white grapes (mostly Petit Manseng, because
				you know) and the result is still a light and fresh red, but with some added acid and concentration that
				comes with the grape that we put in every wine (that’s Petit Manseng, if you were wondering).
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.66
				<br />
				TA: 5.8 g/L
				<br />
				Free SO2 at bottling: Probably ~ 8ppm? 14ppm total addition
				<br />
				Alcohol: 11.7% abv
				<br />
				G/F: 0.5 g/L
				<br />
				Malic Acid: 0.1g/L
				<br />
				Acetic acid at bottling: 0.5 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
