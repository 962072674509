import { React, PropTypes, Classnames } from '@/vendor'
import TinySlider from '../TinySlider'
import styles from './styles.module.scss'

class Slide extends React.PureComponent {
	static propTypes = {
		src: PropTypes.string.isRequired,
	}

	render() {
		return (
			<TinySlider.Slide
				className={styles.image}
				style={{
					backgroundImage: `url(${this.props.src})`,
				}}
			/>
		)
	}
}

class Gallery extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		images: PropTypes.arrayOf(PropTypes.string),
	}

	render() {
		const { className, images = [], ...props } = this.props
		return (
			<TinySlider className={Classnames(styles.gallery, className)} {...props}>
				{images.map((src, idx) => (
					<Slide src={src} key={idx} />
				))}
			</TinySlider>
		)
	}
}

export default Gallery
