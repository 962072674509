import { React } from '@/vendor'
import styles from './styles.module.scss'

export default (props) => (
	<svg viewBox="0 0 306 123" {...props} className={styles.svg}>
		<g>
			<g className={styles.lightwell}>
				<path d="M0 0L1.156 3.54v47.933L0 54.944h27.473l1.663-10.193-3.76 1.011H11.351l-1.085-1.01V3.541l1.085-3.543zM32.967 0l1.157 3.541v47.933l-1.157 3.47h11.35l-1.157-3.47V3.541L44.317 0zM54.438 0L49.811 4.48V50.39l4.627 4.555H72.73l4.555-4.555V29.062l1.156-3.615H63.547l2.024 9.254h2.603v9.905l-1.012 1.084h-7.086l-1.01-1.084V10.12l1.01-1.084h7.086l1.012 1.084v3.615l-1.229 3.47H78.44l-1.156-3.47V4.481L72.73 0zM101.648 0l1.085 3.541v18.002l-1.085 1.085h-7.013l-1.084-1.085V3.541L94.707 0H83.356l1.157 3.542v47.933l-1.157 3.47h11.35l-1.155-3.47v-18.58l1.084-1.084h7.013l1.085 1.084v18.58l-1.085 3.47h11.495l-1.157-3.47V3.541L113.143 0zM118.13 0l-1.662 10.265 3.76-1.012h7.011l1.084 1.012v41.21l-1.156 3.47h11.495l-1.157-3.47v-41.21l1.013-1.012h6.868l3.904 1.012L147.555 0zM196.462.052l.775 2.101-6.46 33.328-4.934-23.916-4.26 20.05 4.016 20.168-.855 3.331h12.284l-.854-3.331 9.45-46.442 2.127-5.29zM173.497.018l.71 1.875.075.281-6.297 32.931-6.395-32.952.644-2.102h-11.15l2.058 5.363 9.66 46.368-.855 3.332h10.536l.693-3.562.032.086 9.616-46.342 2.169-5.278zM211.465 0l1.157 3.47v47.932l-1.157 3.542h27.472l1.664-10.338-3.761 1.084h-14.097l-1.084-1.084V32.75l1.084-1.012h4.627l3.543 1.157V21.398l-3.543 1.158h-4.627l-1.084-1.085v-11.35l1.084-1.085h14.097l3.76 1.084L238.938 0zM244.287 0l1.157 3.541v47.933l-1.157 3.47h27.472l1.664-10.193-3.76 1.011h-14.025l-1.085-1.01V3.541l1.085-3.543zM276.531 0l1.157 3.541v47.933l-1.157 3.47h27.473l1.662-10.188v-.005l-3.759 1.011h-14.025l-1.085-1.01V3.541l1.085-3.543z" />
			</g>
			<g className={styles.survey}>
				<path d="M56.102 67.52l-4.772 4.482v22.991l4.772 4.626h12.58l1.084 1.086v11.567l-1.085 1.012h-7.013l-1.084-1.012v-3.615l1.157-3.47H50.318l1.012 3.47v9.254l4.772 4.554h18.291l4.555-4.554V94.993l-4.555-4.555H61.668l-1.084-1.084V77.785l1.084-1.011h7.013l1.085 1.011v3.616l-1.085 3.471h11.495l-1.228-3.471v-9.399l-4.555-4.482zM103.456 67.52l1.156 3.542v41.21l-1.084 1.012h-7.085l-1.084-1.012v-41.21l1.156-3.542H85.164l1.157 3.542v46.849l4.483 4.554h18.363l4.627-4.554V71.062l1.157-3.542zM119.36 67.52l1.157 3.542v47.933l-1.156 3.47h11.35l-1.157-3.47v-16.267l1.085-1.084h2.964l.723.867 3.904 16.557-.795 3.397h11.35l-1.446-3.687-3.976-17.134 4.627-4.626V72.002l-4.627-4.482H119.36zm10.194 24.003V77.785l1.085-1.011h7.085l1.084 1.011v13.738l-1.084 1.012h-7.085l-1.085-1.012zM173.872 67.52l.796 2.096-6.58 34.413-6.578-34.413.65-2.096h-11.35l2.097 5.35 9.83 46.27-.866 3.325h12.579l-.867-3.325 9.616-46.342 2.168-5.278zM188.403 67.52l1.157 3.47v47.933l-1.157 3.542h27.473l1.663-10.337-3.76 1.083h-14.098l-1.084-1.083V100.27l1.084-1.011h4.627l3.543 1.156V88.92l-3.543 1.157h-4.627l-1.084-1.084V77.641l1.084-1.084h14.098l3.76 1.084-1.663-10.121zM241.975 67.52l1.3 2.168-6.144 20.172-6.145-20.172 1.3-2.168h-13.518l2.674 4.048 11.206 31.74v15.687l-1.156 3.47h11.207l-1.014-3.47v-15.687l11.134-31.74 2.675-4.048z" />
			</g>
		</g>
	</svg>
)
