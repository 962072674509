import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2019 Dos Idiots',
	date: '2021-09-06',
	image: require('./2019-dos-idiots@2x.png'),
	body: (
		<>
			<p>
				A blend of 58% Cabernet Franc, 41% Petit Manseng, and 1% Pinot Gris. Super bright raspberry red fruit in
				color, scent, and in flavor. The most vibrant and electric red we have made and we love it. Its a shame
				we made so little, its gulpable. There are soft subtle ashy tannins on the finish that give it grip.
				We'd recommend slightly chilling and decanting this one for an hour or so. 41 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Mount Airy Vineyard in Fort Defiance, growers Tim and Ben Jordan; Baer Ridge Vineyard in Timberville,
				grower Jody Dodson; Sherman Ridge Vineyard n Woodstock, grower Sunset Hills
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				We took what we learned from Wolves and Los Idiots and came up with a theory that created Dos Idiots. In
				terms of the red wine meets orange wine approach, this shows a bit more of the orange here than in Los
				Idiots, maybe because 42% Petit Manseng means the grape will definitely have an impact. Petit Manseng
				was added whole cluster and the Cabernet Franc was destemmed before the grapes were cofermented. Aged
				for 11 months in neutral French 228L barrels. Bottled unfiltered August 2020.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 4.91
				<br />
				TA: TBD g/L
				<br />
				Free SO2 at bottling: 12 ppm
				<br />
				Total SO2: 37 ppm
				<br />
				Alcohol: 13.7 abv
				<br />
				G/F: 0.27 g/L
				<br />
				Malic Acid: 0.02 g/L
				<br />
				Acetic acid at bottling: 0.84 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
