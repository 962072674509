import { Button, Fs, Gallery, Grid, Img, LinkCell, Masthead, MastheadSubpage } from '@/components'
import { useMq } from '@/components/Mq'
import { Helmet, React } from '@/vendor'
import styles from './styles.module.scss'

const CLUB_URL = 'https://vinoshipper.com/shop/lightwell_survey_wines/join-our-club'

const CLUBS = [
	{
		title: 'The Ring Leader',
		image: require('./assets/ring-leader@2x.png'),
		body: (
			<ul>
				<li>2 Mixed Shipments a Year (Spring/Fall)</li>
				<li>6 Bottles Per Shipment</li>
				<li>15% Off All Purchases. Prices will vary between $150 - $180 per release after your discount.</li>
				<li>Guaranteed 1 Library Wine a Year</li>
				<li>Access to Library Wines</li>
				<li>Free Shipping on Club Orders</li>
				<li>Free Ticket to Pinko (Spring Release Party) and Hellawine (Fall Release Party)</li>
				<li>Free Glass o’ Juice For You When Visiting Us In the Tasting Room</li>
				<li>Exclusive Members Only Pin</li>
			</ul>
		),
	},
	{
		title: 'The Boss Hostler',
		image: require('./assets/boss-hostler@2x.png'),
		body: (
			<ul>
				<li>2 Mixed Shipments a Year (Spring/Fall)</li>
				<li>3 Bottles Per Shipment</li>
				<li>10% Off All Purchases. Prices will vary between $70 - $90 per release after your discount.</li>
				<li>Free Glass o’ Juice For You When Visiting Us In the Tasting Room</li>
				<li>Exclusive Members Only Pin</li>
			</ul>
		),
	},
]

const ClubRoute = () => {
	const Mq = useMq()
	return (
		<>
			<Helmet>
				<title>Club</title>
			</Helmet>
			<MastheadSubpage
				masthead={
					<Masthead
						background={{
							image: require('./assets/club.png'),
							title: 'Club',
						}}
						foreground={
							<img src={require('./assets/header-join-the-circus@2x.png')} alt="Join the circus" />
						}
					/>
				}
				body={
					<>
						<Grid stacked={!Mq.large}>
							<Grid.Cell>
								<Grid.Content
									pad={1}
									children={
										<Fs align="center" contained>
											<p>
												<strong>Welcome to The Circus</strong>, Lightwell Survey's{' '}
												<a href={CLUB_URL}>exclusive clown club</a>. Under our little tent,
												you'll discover a world of wonders, flea bags, and wine! As a club
												member, you'll be the first to experience Lightwell's latest releases
												along with exclusive benefits from either level of membership: Ring
												Leader or Boss Hosler. But that's not all - we want to hang with you
												IRL! You'll also enjoy the type of treatment Mom would get at our
												shindigs and tasting room, heavy pours of the finest beverages, plus
												some fun fringe schwag. Join us as we and our wines travel from town to
												town, spreading laughter and candy butchers wherever we go.
											</p>
											<p>
												<img src={require('./assets/circus@2x.jpg')} />
											</p>
										</Fs>
									}
								/>
							</Grid.Cell>
							<Grid.Cell>
								<Grid stacked>
									{CLUBS.map((club, idx) => (
										<Grid.Cell key={idx}>
											<Grid.Content
												pad={1}
												children={
													<div className={styles.club}>
														<div className={styles.club__image}>
															<img src={club.image} alt={club.title} />
														</div>
														<div className={styles.club__content}>
															<Fs>
																<Fs variant="h2" tag="h2">
																	{club.title}
																</Fs>
																{club.body}
															</Fs>
															<br />
															<Button tag="a" href={CLUB_URL} target="_blank">
																Join the club
															</Button>
														</div>
													</div>
												}
											/>
										</Grid.Cell>
									))}
								</Grid>
							</Grid.Cell>
						</Grid>
						<Grid>
							<Grid.Cell>
								<Grid stacked={Mq.small}>
									<Grid.Cell>
										<Grid stacked>
											<Grid.Cell>
												<LinkCell to={CLUB_URL} pre="Wine Club" primary="Join Today!" />
											</Grid.Cell>
											{Mq.large && (
												<Grid.Cell flex={2}>
													<Grid.Content bg={require('./assets/pin@2x.jpg')} />
												</Grid.Cell>
											)}
										</Grid>
									</Grid.Cell>
									<Grid.Cell flex={2}>
										<Gallery
											images={[
												require('./gallery/club-gallery-1.jpg'),
												require('./gallery/club-gallery-2.jpg'),
												require('./gallery/club-gallery-3.jpg'),
												require('./gallery/club-gallery-4.jpg'),
												require('./gallery/club-gallery-5.jpg'),
												require('./gallery/club-gallery-6.jpg'),
												require('./gallery/club-gallery-7.jpg'),
											]}
										/>
									</Grid.Cell>
									<Grid.Cell>
										<Grid stacked>
											<Grid.Cell>
												<LinkCell to="/shop" pre="Already a member?" primary="Shop Wine" />
											</Grid.Cell>
											<Grid.Cell flex={2}>
												<Grid.Content pad={2}>
													<Img src={require('./assets/W@2x.png')} />
												</Grid.Content>
											</Grid.Cell>
										</Grid>
									</Grid.Cell>
								</Grid>
							</Grid.Cell>
						</Grid>
					</>
				}
			/>
		</>
	)
}

export default ClubRoute
