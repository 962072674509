import { React, PropTypes, Classnames, NavLink as BaseNavLink } from '@/vendor'
import styles from './styles.module.scss'

const NavLink = ({ className, ...props }) => {
	const classnames = React.useMemo(() => {
		return Classnames(styles.navLink, className)
	}, [className])
	if (typeof props.to === 'string' && props.to.startsWith('http')) {
		return (
			<a href={props.to} className={classnames} target="_blank">
				{props.children}
			</a>
		)
	}
	return <BaseNavLink activeClassName={styles.active} className={classnames} {...props} />
}

NavLink.propTypes = {
	className: PropTypes.string,
}

export default NavLink
