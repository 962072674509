import { Fs, Gallery, Grid, Img, LinkCell, Mq, ShopCarousel } from '@/components'
import { Helmet, React } from '@/vendor'
import styles from './styles.module.scss'

class IndexRoute extends React.PureComponent {
	render() {
		return (
			<>
				<Helmet titleTemplate="">
					<title>Lightwell Survey Wines :: Makin' Wine Straight Outta Virgina</title>
				</Helmet>
				<Mq>
					{(Mq) => (
						<Grid stacked>
							<Grid.Cell>
								<Grid stacked={!Mq.large}>
									<Grid.Cell>
										<Grid stacked>
											<Grid.Cell>
												<Grid.Content className={styles.shopCarouselWrapper}>
													<ShopCarousel>
														<ShopCarousel.Slide
															image={require('./currentReleases/2022-goodbye-horses@2x.jpg')}
															title="2022 Goodbye Horses"
														/>
														<ShopCarousel.Slide
															image={require('./currentReleases/2023-redirecting-the-light@2x.jpg')}
															title="2023 Redirecting The Light"
														/>
														<ShopCarousel.Slide
															image={require('./currentReleases/2023-the-way-it-was-before@2x.jpg')}
															title="2023 The Way It Was Before"
														/>
														<ShopCarousel.Slide
															image={require('./currentReleases/2021-wolves@2x.jpg')}
															title="2021 The Weird Ones Are Wolves"
														/>
														<ShopCarousel.Slide
															image={require('./currentReleases/2021-dos-idiots@2x.jpg')}
															title="2021 Dos Idiots"
														/>
														<ShopCarousel.Slide
															image={require('./currentReleases/2021-goodbye-horses@2x.jpg')}
															title="2021 Goodbye Horses"
														/>
													</ShopCarousel>
												</Grid.Content>
											</Grid.Cell>
											<Grid.Cell flex={0}>
												<Grid.Content light>
													<Fs align="center">🍷 FREE Ground Shipping 🚚</Fs>
												</Grid.Content>
											</Grid.Cell>
										</Grid>
									</Grid.Cell>
									<Grid.Cell>
										<Grid stacked>
											<Grid.Cell>
												<Grid.Content pad={1} light className={styles.quote}>
													<Fs align="center">
														“Lightwell Survey Wines produces small batches of lively reds,
														dense whites and unique combinations of the two.”
													</Fs>
													<Img
														src={require('./signature-sebastian@2x.png')}
														className={styles.signed}
													/>
													<Img
														src={require('./little-larry@2x.png')}
														className={styles.larry}
													/>
												</Grid.Content>
											</Grid.Cell>
											<Grid.Cell flex={2}>
												<Grid stacked={Mq.small}>
													<Grid.Cell>
														<Grid stacked>
															<Grid.Cell>
																<LinkCell
																	to="/club"
																	pre={<Img src={require('./wine-club@2x.png')} />}
																	primary="Experience Lightwell, Join the Circus"
																	light
																/>
															</Grid.Cell>
															<Grid.Cell>
																<LinkCell
																	to="/about"
																	pre="Winemaking"
																	primary="Our Story"
																/>
															</Grid.Cell>
														</Grid>
													</Grid.Cell>
													<Grid.Cell>
														<Grid.Content bg={require('./pic1@2x.jpg')} />
													</Grid.Cell>
												</Grid>
											</Grid.Cell>
										</Grid>
									</Grid.Cell>
								</Grid>
							</Grid.Cell>
							<Grid.Cell>
								<Grid stacked={!Mq.large}>
									<Grid.Cell>
										<Grid stacked>
											<Grid.Cell>
												<LinkCell to="/visit" pre="Tasting Room" primary="Book a Tasting" />
											</Grid.Cell>
											{Mq.large && (
												<Grid.Cell flex={2}>
													<Grid.Content bg={require('./pic2@2x.jpg')} />
												</Grid.Cell>
											)}
										</Grid>
									</Grid.Cell>
									<Grid.Cell flex={2}>
										<Gallery
											images={[
												require('./gallery/home-gallery-1.jpg'),
												require('./gallery/home-gallery-2.jpg'),
												require('./gallery/home-gallery-3.jpg'),
												require('./gallery/home-gallery-4.jpg'),
												require('./gallery/home-gallery-5.jpg'),
												require('./gallery/home-gallery-6.jpg'),
												require('./gallery/home-gallery-7.jpg'),
												require('./gallery/home-gallery-8.jpg'),
											]}
										/>
									</Grid.Cell>
									<Grid.Cell>
										<Grid stacked>
											<Grid.Cell>
												<LinkCell to="/shop" pre="Our Cellar" primary="Buy Wine" light />
											</Grid.Cell>
											<Grid.Cell flex={2}>
												<Grid.Content pad={2}>
													<Img src={require('./W@2x.png')} />
												</Grid.Content>
											</Grid.Cell>
										</Grid>
									</Grid.Cell>
								</Grid>
							</Grid.Cell>
						</Grid>
					)}
				</Mq>
			</>
		)
	}
}

export default IndexRoute
