import { React, Helmet } from '@/vendor'
import { Grid, Fs } from '@/components'

class MailingListConfirmationRoute extends React.PureComponent {
	render() {
		return (
			<>
				<Grid stacked>
					<Grid.Cell>
						<Grid.Content pad={2}>
							<Fs variant="h2" tag="h2">
								You're in!
							</Fs>
							<br />
							<img src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExbDE4NXphYjlteWgzcmJwYTM3MXFoZ284cTc0d2x5bjZ2ZHRiOTE5aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l0MYt5jPR6QX5pnqM/giphy.gif" />
						</Grid.Content>
					</Grid.Cell>
				</Grid>
			</>
		)
	}
}

export default MailingListConfirmationRoute
