import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2022 Hellawine Release Party',
	date: '2022-09-26',
	image: require('./2022-hellawine@2x.jpg'),
	body: (
		<>
			<p>
				This Hellawine, let’s uncork those demons and let Bacchus sort ‘em out! Join us in the revelry this
				October 29th to celebrate the release of our 2020 wines, feast on local eats*, and dabble in the occult
				at our most curious tasting room in Waynesboro.
			</p>
			<p>Costumes are encouraged but not required. Your aura will suffice. Let’s get weird!</p>
			<p>
				Looking to pre order our wines? Hell yeah! Our 2020’s are available for presale for pickup at Hellawine.
				Please select <strong>“Winery Pickup”</strong> at check out. If you sadly can't make it and need us to
				ship the new juice to you we will start shipping the second week of November!
			</p>
			<p>
				<strong>TICKETS INCLUDE</strong>
				<br />
				<ul>
					<li>Tasting of All 2020 wines (plus some oldies)</li>
					<li>Live Entertainment</li>
					<li>Spooky Vibes</li>
				</ul>
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				October 29rd from 1-5PM
				<br />
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Lightwell Survey Winery & Tasting Room
				<br />
				200 W 12TH ST, UNIT# D53
				<br />
				Waynesboro, VA 22980
				<br />
				<a href="https://g.page/lightwellsurvey?share" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
			<p>*All food will be available for purchase to support our awesome and talented foodie homies</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
