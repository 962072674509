import { React } from '@/vendor'

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		style={{
			background: '#252527',
		}}
		{...props}
	>
		<g fill="none" fillRule="evenodd">
			<path fill="#252527" d="M0 0h24v24H0z" />
			<path
				fill="#FFF"
				d="M12 0c6.625.007 11.996 5.375 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0ZM9.782 7H6l4.484 5.922-4.197 4.968h1.939l3.174-3.757 2.818 3.723H18l-4.606-6.084 3.973-4.703h-1.94l-2.95 3.492L9.783 7Zm-.517 1.037 6.648 8.781h-1.178l-6.648-8.78h1.178Z"
			/>
		</g>
	</svg>
)
